 .ask-btn {
   color: var(--button-color);
   padding: 14px 15px;
   font-size: var(--font-size-18);
   margin-left: var(--gap-half);
 }

 #boundary-box {
   box-sizing: border-box;
   border: none;
   padding: 0 1rem;
   padding-bottom: 0rem;
   background: #f9fafb;
   height: 100vh;
 }

 #response-box {
   box-sizing: border-box;
   width: 100%;
   overflow-y: auto;
   transition: 0.2s ease-in-out;
   padding-right: 15px;
 }
 .textStyle {
  width: 100%;
 }
.textStyle p {
  margin: 0 0 15px;
}
.textStyle p a {
  color: #3C82F6;
}
 .query-box {
   box-sizing: border-box;
   border: none;
   border-bottom: 0.125rem solid var(--button-color);
   background: white;
   padding: 0.9rem 6.25rem 0.9rem 6.25rem;
   padding-left: 1rem;
   width: 100%;
   height: 6rem;
   color: #31302f;
   resize: vertical;
   min-height: 3rem;
   height: 3rem;
   overflow: hidden;
   font-size: 1rem;
   border-radius: none;
   font-weight: 300;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell
 }

 .query-box:focus {
   border-bottom: 0.125rem solid var(--global-color);
   line-height: 1.063rem;
 }

 .query-box:focus-visible {
   outline: 0;
 }

 .chat-content {
   box-sizing: border-box;
   color: black;
   margin-top: .5rem;
   width: 100%;
   height: fit-content;
   font-weight: 300;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell;
   padding-right: 25px;
 }

 .usrMsg {
   word-break: break-word;
   margin-top: .5rem;
   font-size: var(--font-size-16);
 }

 #loadingimg {
   height: 1rem;
   margin-left: 0.5rem
 }

 .details-container {
   max-height: 10rem;
   height: fit-content;
   overflow-y: scroll;
   border-radius: var(--global-border-radius);
   border: solid var(--global-color);
   padding: 0.5rem;
   width: 90%;
 }

 #boundary-box h4 {
   margin: 0 0 10px;
 }

 .welcomeMsgOuter.promptSpacing {
   margin-bottom: .5rem;
 }


 .welcomeMsgOuter {
   display: flex;
   margin-bottom: 1rem;

 }

 .welcome-icon {
   height: 2.5rem;
   width: 2.5rem;
   border-radius: 50%;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: .75rem;
 }


 .toolTip {

   width: 2.1rem;
   height: 1rem;
   background-color: black;
   color: white;
   border-radius: 10rem;
   position: absolute;
   bottom: -3px;
   right: -10px;
   text-align: center;
   line-height: 18px;
   font-size: 0.5rem;
   font-weight: bold;
   display: none;

 }

 .welcome-icon:hover .toolTip {
   display: block;
 }


 #changeicon[src="GooglePalm.svg"]+.toolTip,
 #changeicon[src="AmazonTitan.svg"]+.toolTip,
 #changeicon[src="GeminiPRO.svg"]+.toolTip {
   display: none;
 }

 .welcome-msg h2 {
   margin-top: 0;
   font-size: 1.35rem;
   line-height: 2.25rem;
   font-weight: 400;
   color: #000;
 }
 
 .welcome-msg p{
  padding-left: 0.5rem;
  color: #000;

 }

 .msgBox {
   display: flex;
   margin-bottom: 1rem;
   word-break: break-word;
   position: relative;
 }

 .welcome-icon.profile-icon {
   background: var(--global-gradient-color);
   color: var(--global-white-color);
   display: flex;
   font-weight: 600;
 }

 #changeicon {
   padding: 0.6rem;
 }

 .textarea-outer {
   position: relative;
   z-index: 9;
   left: 0;
   right: 0;
 }

 .labelStyle {
   font-size: var(--pharos-form-element-size-text-label);
   line-height: var(--pharos-line-height-small);
   margin-bottom: var(--pharos-spacing-one-quarter-x);
   font-family: var(--pharos-font-family-sans-serif);
   font-weight: var(--pharos-font-weight-bold);
   color: var(--pharos-form-element-color-text-base);
 }

 .table {
   width: 100%;
   text-align: left;
   border: 1px solid #ccc;
   border-right: none;
 }

 .table th,
 .table td {
   border-bottom: 1px solid #ccc;
   padding: 5px;
   border-right: 1px solid #ccc;
 }

 .table tr:last-child td {
   border-bottom: none;
 }

 .table-outer {
   width: 100%;
   margin: 50px auto;
 }

 .GdoUser {
   color: var(--global-color);
   margin-top: -10px;
 }

 .value-cell {
   white-space: pre-wrap;
 }

 .toolTip {

   width: 2.6rem;
   height: 1rem;
   background-color: black;
   color: white;
   border-radius: 10rem;
   position: absolute;
   bottom: -3px;
   right: -10px;
   text-align: center;
   line-height: 18px;
   font-size: 0.5rem;
   font-weight: bold;
   display: none;

 }

 .welcome-icon:hover .toolTip {
   display: block;
 }

 #changeicon[src="GooglePalm.svg"]+.toolTip,
 #changeicon[src="AmazonTitan.svg"]+.toolTip,
 #changeicon[src="GeminiPRO.svg"]+.toolTip {
   display: none;
 }

 .color-red {
   color: var(--global-color-red)
 }


 [data-title] {
   position: relative;
 }

 .flex-width {
   min-width: 19rem;
 }

 .form-outer {
   position: relative;
   width: 100%;
 }

 .file-box {
   position: absolute;
   right: -50px;
   top: 0;
   display: flex;
 }

 .file-size,
 .chat-icon {
   width: 4.25rem;
   height: 2.25rem;
   overflow: hidden;
   position: relative;
   margin-right: var(--gap-full);
 }

 .chat-icon, .clearchat {
   width: 2.5rem;
   height: 2.5rem;
   margin-right: .625rem;
 }

 .chat-icon-position {
   position: absolute;
   left: 0;
 }

.query-input-actions {
  display: flex;
  float: right;
  justify-content: space-between;
  align-items: center;
  gap: .75rem;
  padding: 0.5rem 1.25rem 0.5rem 0rem;
}

.query-input-action-item button {
  width: 1.86rem;
}

.query-input-action-item button>img {
  height: 1.5rem;
}

.query-input-action-item {
  position: relative;
  overflow: hidden;
  width: 1.5rem;
  height: 1.5rem;
}

/* this code is required to make cursor pointer on hover of file upload in query input actions */
.query-input-action-item input::after {
  content: '';
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#demo-simple-select {
  display: flex;
  gap: 7px;
  align-items: center;
  color: #000;
  font-size: 14px;
}

.llm-models-list {
  gap: 0.45rem; 
}

.Mui-selected.llm-models-list {
  background-color: #F2F0FF !important;
  border-radius: 4px;
  height: 2.37rem;
  color: #000;
  font-weight: 700;
}

ul[aria-labelledby="demo-simple-select-label"] {
padding: 1rem;
}

 .model-text,
 .model-box {
   font-size: var(--font-size-14);
 }

 .model-box span {
   margin-right: var(--gap-half)
 }

 .MuiSelect-select {
   font-size: var(--font-size-14);
 }
 #loadingimg {
  height: 1rem; margin-left: 0.5rem
}
.query-part  {
  margin-bottom: 1.25rem;
}
.dataset, .edit-datalist {
  list-style: none;
  padding: 0;
  margin: 0
}
.dataset li {
  margin-bottom: 1rem;
}
.beat {
  animation: beat .50s infinite alternate;
  transform-origin: center;
}
@keyframes beat{
  to { transform: scale(1.4); }
}
.edit-datalist li {
  color: rgb(37, 106, 255);
  text-decoration: underline;
  margin-bottom: .875rem;
  cursor: pointer;
}
.buttonShowOnHover {
  opacity: 0;
  transition: all 300ms ease-in-out;
  visibility: hidden;
}
.dataList_ul li:hover .buttonShowOnHover,
.agents-list:hover .buttonShowOnHover,
.teams-list:hover .buttonShowOnHover {
  opacity: 1;
  visibility: visible;
}
.chat-content table {
  border-collapse: collapse;
  margin: 1em 0;
}

.chat-content th, .chat-content td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;
}

.chat-content th {
  background-color: #f4f4f4;
}
 @media(orientation:portrait) {
   .chat-outer {
     display: block;
   }

   .mr-50 {
     margin-right: 0;
   }

   .agent-outer {
     width: 100%;
     margin-left: 0;
   }


   #response-box {
     width: 100%;
   }

   msal-auth>chat-interface {
     width: auto;
   }
 }

 @media (max-width: 1140px) {
   .chat-outer {
     flex-direction: column;
   }

   .agent-outer {
     width: auto;
     margin-left: 0;
   }

   .temperature-range {
     margin-left: 0;
     margin-top: 1rem;
   }

   .field-value #rangeValue,
   .field-value {
     width: 100%
   }

   .mr-50 {
     margin-right: 0;
   }

   .input-column.d-flex {
     flex-direction: column;
   }
 }

 .agent-name-tooltip, .hide-tooltip {
   position: relative;
   display: inline-block;
 }

 .agent-name-tooltip .agent-name-tooltip-text, .hide-tooltip .agent-name-tooltip-text {
   visibility: hidden;
   max-width: 300px;
   background-color: rgba(97, 97, 97, 0.92);
   color: rgb(255, 255, 255);
   font-weight: 500;
   text-align: center;
   border-radius: 4px;
   font-family: Roboto, Helvetica, Arial, sans-serif;
   font-size: 0.6875rem;
   padding: 5px;
   position: absolute;
   z-index: 1;
   top: 150%;
   left: 50%;
   margin-left: -50px;
   opacity: 0;
   transition: opacity 0.3s;
 }

 .agent-name-tooltip:hover .agent-name-tooltip-text {
   visibility: visible;
   opacity: 1;
 }

 .agent-name-tooltip .agent-name-tooltip-text::after {
   content: "";
   position: absolute;
   top: -10px;
   left: 50%;
   margin-left: -5px;
   border-width: 5px;
   border-style: solid;
   border-color: transparent transparent rgba(97, 97, 97, 0.92) transparent;
 }
 
 .msgBox.chat-content{
  background: linear-gradient(180deg, #f9f8f6 0%, #f3f2ed 100%);
  box-shadow: 0px 4px 33px 0px #00000012 inset;
  background-color: #FBFAFA;
  padding: 8px 12px;
  border-radius: 16px;
  max-width: 80%;
  width: fit-content;
 }
 
.highlight-background-content{
  background: linear-gradient(to bottom, rgba(254, 254, 254, 1) 100%, rgba(250, 250, 250, 0.97) 40%, rgb(250 250 250 / 61%) 57%);
  box-shadow: 0px 2px 15px 0px #f2f2f2 inset;
  border-radius: 16px;
  max-width: 80%;
  width: fit-content;
  padding: 8px 12px;
}
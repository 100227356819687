.create-agent-sub-panel {
    /* top: 5rem !important; */
    width: 100% !important;
    overflow: auto;
}

.mui-form-label {
    color: #0B0C0C !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.mui-form-label+span svg {
    color: #4282FE !important;
}

.create-agent-form {
    padding: 0% 5% 0% 20%;
}

.create-agent-header {
    padding: 20px 0px;
}

.create-agent-header>p {
    color: #000;
    font-weight: 700;
    font-size: 22px;
    text-align: center
}

.agent-name-field {
    display: flex;
    align-items: center;
    gap: 40px;
}

.create-agent-accordion-section {
    display: flex;
    flex-flow: column;
    gap: 16px;
}

.create-agent-accordion {
    border: 1px solid #DBD4D4 !important;
    border-radius: 4px !important;
    box-shadow: 0px 4px 11px 0px #0000000D !important;
    color: #3C82F6 !important;
    font-weight: 700;
    font-size: 18px;
}

.MuiAccordionSummary-expandIconWrapper svg[data-testid="ExpandMoreIcon"] {
    color: #3C82F6 !important;
}

.create-agent-actions {
    float: right !important;
    gap: 34px !important;
    display: flex !important;
    padding-bottom: 50px !important;
}

.MuiSlider-valueLabel {
    border-radius: 5px !important;
    background-color: #303030 !important;
    height: 15px !important;
    width: 5px !important;
    top: 5px !important;
    transform: rotate(180deg) !important;
}

.MuiSlider-valueLabel>span {
    transform: rotate(180deg) !important;
}

.MuiSlider-track {
    /* background: linear-gradient(to right, #25D8FF 0%, #3C82F6 25%, #3C82F6 75%, #FF2559 100%); */
    height: 8px !important;
}

.MuiSlider-thumb {
    background-color: #303030 !important;
    box-shadow: 0px 2px 4px 0px #0000001A !important;
}

.MuiSlider-rail {
    background-color: #EBEBEB !important;
    height: 8px !important;
}

.slider-marks {
    color: #6F6C6C !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.accordion-opened {
    border-top: 1px solid #3C82F6 !important;
    border-right: 1px solid #3C82F6 !important;
    border-bottom: 1px solid #3C82F6 !important;
    border-left: 6px solid #3C82F6 !important;
}
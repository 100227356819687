body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
--global-color: #3C82F6;
--global-border-radius: 0.25rem;
--global-gradient-color: linear-gradient(135deg,  #3C82F6,#3C82F6);
--icon-gradient-color: linear-gradient(180deg, #3C82F6, #3C82F6);
--global-white-color: #fff;
--global-black-color: #000;
--file-element-color: rgba(242, 235,250, 0.8);
--global-filecontent-hover: #cdfccd;
--global-medium-font-weight:500;
--global-extra-bold-font-weight:800;
--global-bold-font-weight:700;
--global-semi-bold-font-weight:600;
--global-normal-font-weight:400;
--global-semi-normal-font-weight:300;
--global-light-font-weight:200;
--global-file-container-border: #4282FE;
--global-progress-border-color: #B3B9C4;
--global-progress-done-color: #6DC24B;
--global-form-border: #ccc;
--global-gray: #808080;
--global-dark-gray: #555555;
--modal-header-color: #f1ebf9;
--global-text-color: #4E4F4F;
--global-link-color: #4282FE;
--global-grey-text: #50595F;
--global-accordion-color: #000;
--color-white: #fff;
--global-color-red: red;
--button-bg: #DADADB;
--button-color: #737373;
--font-size-16:1rem;
--font-size-14: 0.875rem;
--font-size-18:1.125rem;
--gap-half: 0.625rem; 
--gap-full: 1.25rem;
}
button {
  border: none;
  padding: var(--gap-half);
  border-radius: var(--global-border-radius);
  font-size: var(--font-size-16);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.text-center {
  text-align: center;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-none {
  display: none;
}
.align-items-start {
  align-items: flex-start;
}
label {
  margin-bottom: 0 !important;
}
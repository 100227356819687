:host {
    font-family: sans-serif;
}

.login-outer {
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login-style {
    border-radius: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
    align-items: center;
}

.login-l {
    text-align: center;
    width: 50%;
}

.login-l a {
    color: var(--global-color);
}

:host([variant="primary"]) #button-element {
    background: #fff !important;
    border: 1px solid red;
    border-radius: 0.125rem;
    color: red;
}

.my-15 {
    margin: 0.938rem 0;
}

.login-r {
    background: #f5f5f5;
    width: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 7.5rem 0;
    color: #fff;
    border-top-right-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
    color: #3C82F6;
}

.login-r h2 {
    font-size: 2.5rem;
    margin: 0 7.5rem 3.125rem;
    line-height: 3.438rem;
}

.login-r p {
    font-size: 1.25rem;
    margin: 1.25rem 7.5rem;
    font-weight: 300;
}

.logo {
    color-scheme: light;
    width: 170px;
}

@media (orientation: landscape) {

    .login-r h2,
    .login-r p {
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .login-r h2 {
        line-height: 2.5rem;
        font-size: 1.8rem;
        margin: 0 3rem 3rem;
    }

    .login-r p {
        font-size: 1rem;

        font-weight: 300;
    }
}

@media only screen and (max-height: 42.5em) {
    .login-r {
        padding: 2.5rem 0;
        height: calc(100vh - 140px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .login-r h2 {
        line-height: 2rem;
        font-size: 1.2rem;
        margin: 1.5rem 2rem 1.5rem;
    }

    .login-r h2 br {
        display: none;
    }

    .login-r p {
        margin: 0px 2rem 0.7rem;
    }
}

@media (orientation: portrait) and (max-height: 42.5em){
    .login-r  {
        height: calc(100vh - 430px);
    }
}

@media only screen and (max-height: 22em) {
    .login-r {
        padding: 0;
        height: inherit;
    }
}

@media (orientation: portrait) {
    .d-flex.login-style {
        display: block;
    }

    .login-l,
    .login-r {
        width: 100%;
    }

    .login-style,
    .login-r {
        border-radius: 0;
    }

    .login-r {
        padding: 2rem 0;
    }

    .login-l {
        padding: 1.875rem 0;
    }

    .login-r h2,
    .login-r p {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

    .login-r h2 {
        margin-bottom: 1.5rem;
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .login-r p {
        font-size: 0.8rem;
        font-weight: 300;
        margin-bottom: 0;
        margin-top: 0.313rem;
    }

}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--pharos-color-white);
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    box-shadow: 0 0 1.625rem rgba(0,0,0,0.1);
}
.modal-header {
    text-align: center;
    font-size: 1.25rem;    
    padding: 1rem;
    background: #e4e4e4
}
.modal-header h4 {
    margin: 0;
    color: var(--global-text-color);
}
.scroll-spy {
    overflow-y: auto;
    height: 50vh;
}
.modal-body {
    text-align: left;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    color: var(--global-grey-text);
    background: #fff;
}
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.575rem 1rem;
    border-top: 0.063rem solid var(--pharos-color-marble-gray-94);
    background: #e4e4e4
}
.justify-content-between {
    justify-content: space-between;
}
input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    overflow: visible;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
    vertical-align: 0.1rem;
    font-size: 0.938rem;
}
.modal.fade .modal-dialog{
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
}

.modal-lg {
    max-width: 80%;
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-20%, -50%);
}
.modal-md {
    max-width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
    display: flex;
    align-items: center;
}   
.fade {
    opacity: 1;
    transition: opacity .15s linear;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,0.9);
    overflow: hidden;
    outline: 0;
    z-index: 99;
}
.modal-body p {
    font-size: 0.813rem;
    line-height: 1.188rem;
}
.modal-body ul li, ol li {
    font-size: 0.813rem;
    line-height: 1.188rem;
    margin-bottom: 0.938rem;
} 
 .chat-content ol li {
    font-size: inherit;
}
@media (orientation: portrait) {
    .modal-lg {
        max-width: 90%;
        position: absolute;
        top: 50%;
        left: -20%;
        transform: translate(28%, -50%);
    }
    .input-wrapper {
        width: 21.875rem;
    }
    .modal-footer div {
        width: 75%;
    }
}